/* eslint-disable max-lines */
import history from 'Util/History';

import { changeTagName } from '../util/scripts';

// Replace all doesn't work on mobile android

// eslint-disable-next-line arrow-body-style
const replace = (str, replace, replaceWith) => {
    return str.replaceAll ? str.replaceAll(replace, replaceWith) : str.replace(replace, replaceWith);
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurationData: state.ConfigReducer.seoConfigurationData,
        description: state.MetaReducer.description,
        keywords: state.MetaReducer.keywords,
        canonical_url: state.MetaReducer.canonical_url,
        store_code: state.ConfigReducer?.store_code
    };
};

const containerProps = (args, callback, instance) => {
    const { seoConfigurationData } = instance.props;

    return {
        ...callback(...args),
        seoConfigurationData
    };
};

const shouldUpdateRobots = (pages = []) => {
    // eslint-disable-next-line fp/no-let
    let output = false;

    pages?.forEach((path) => {
        const resultRegExp = path.includes('*')
            ? replace(path, '*', '.*')
            : path.concat('$');

        if (new RegExp(resultRegExp).test(history.location.pathname)) {
            output = true;
        }
    });

    return output;
};

const shouldRemoveCanonicals = (pages = []) => {
    // eslint-disable-next-line fp/no-let
    let output = false;

    pages?.forEach((path) => {
        const resultRegExp = path.includes('*')
            ? path.replaceAll('*', '.*')
            : path.concat('$');

        if (new RegExp(resultRegExp).test(history.location.pathname)) {
            output = true;
        }
    });

    return output;
};

const addTrailing = (url = '', shouldAdd = false) => {
    if (url) {
        const { length } = url;

        if (url.includes('.html') || url.includes('.htm') || url.includes('?')) {
            return url;
        }

        if (shouldAdd) {
            if (url[length - 1] !== '/') {
                return `${url}/`;
            }

            return url;
        }

        if (url[length - 1] === '/') {
            return url.slice(0, length);
        }
    }

    return url;
};

const removeAdditionalCanonicals = () => {
    const canonicals = Array.from(document.querySelectorAll('link[rel="canonical"]')) || [];
    if (canonicals.length > 0) {
        canonicals.map((obj, index) => {
            if (index === 0) {
                return false;
            }
            obj.remove();
            return true;
        });
    }
};

const updateRobots = (props) => {
    const {
        seoConfigurationData: {
            noindex_follow_pages = '',
            noindex_follow_user_pages = '',
            noindex_nofollow_user_pages = '',
            disable_canonicals_pages = '',
            add_trailing_slash_canonicals,
            add_trailing_slash_canonicals_hp
        } = {},
        meta_title: title,
        description,
        keywords,
        canonical_url,
        status_code,
        updateMeta,
        store_code
    } = props;

    // eslint-disable-next-line fp/no-let
    let currentMeta = {
        title,
        description,
        keywords,
        canonical_url,
        status_code
    };

    const noindexFollowPages = /\S/.test(noindex_follow_pages) ? noindex_follow_pages.split(',') : '';
    const noindexFollowUserPages = /\S/.test(noindex_follow_user_pages) ? noindex_follow_user_pages.split(',') : '';
    const noindexNofollowUserPages = /\S/.test(noindex_nofollow_user_pages)
        ? noindex_nofollow_user_pages.split(',') : '';

    if (!canonical_url) {
        currentMeta = {
            ...currentMeta,
            canonical_url: window.location.href
        };
        updateMeta({
            ...currentMeta,
            canonical_url: window.location.href
        });
    }

    if (typeof noindexFollowPages !== 'string' && shouldUpdateRobots(noindexFollowPages)) {
        currentMeta = {
            ...currentMeta,
            robots: 'noindex, follow'
        };
        updateMeta({
            ...currentMeta,
            robots: 'noindex, follow'
        });
    }

    if (typeof noindexFollowUserPages !== 'string' && shouldUpdateRobots(noindexFollowUserPages)) {
        currentMeta = {
            ...currentMeta,
            robots: 'noindex, follow'
        };
        updateMeta({
            ...currentMeta,
            robots: 'noindex, follow'
        });
    }

    if (typeof noindexNofollowUserPages !== 'string' && shouldUpdateRobots(noindexNofollowUserPages)) {
        currentMeta = {
            ...currentMeta,
            robots: 'noindex, nofollow'
        };
        updateMeta({
            ...currentMeta,
            robots: 'noindex, nofollow'
        });
    }

    const disableCanonicalForPages = /\S/.test(disable_canonicals_pages) ? disable_canonicals_pages?.split(',') : '';
    const realPath = window.location.pathname.replace(`/${store_code}`, '');

    if (typeof disableCanonicalForPages !== 'string' && shouldRemoveCanonicals(disableCanonicalForPages)) {
        currentMeta = {
            ...currentMeta,
            canonical_url: undefined
        };
        updateMeta({
            ...currentMeta,
            canonical_url: undefined
        });
    }

    if (realPath === '/' && currentMeta.canonical_url) {
        currentMeta = {
            ...currentMeta,
            canonical_url: addTrailing(
                currentMeta.canonical_url,
                add_trailing_slash_canonicals_hp
            )
        };
        updateMeta({
            ...currentMeta,
            canonical_url: addTrailing(
                currentMeta.canonical_url,
                add_trailing_slash_canonicals_hp
            )
        });
    }

    if (realPath !== '/' && currentMeta.canonical_url) {
        currentMeta = {
            ...currentMeta,
            canonical_url: addTrailing(
                currentMeta.canonical_url,
                add_trailing_slash_canonicals
            )
        };
        updateMeta({
            ...currentMeta,
            canonical_url: addTrailing(
                currentMeta.canonical_url,
                add_trailing_slash_canonicals
            )
        });
    }
};

const replaceH1 = () => {
    const h1 = Array.from(document.querySelectorAll('h1[data-content-type="heading"]'));

    if (h1.length > 0) {
        h1.forEach((el) => {
            changeTagName(el, 'p');
        });
    }
};

const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const { isLoading, meta_title: title } = instance.props;

    const [prevProps] = args;
    const { isLoading: prevIsLoading, meta_title: prevTitle } = prevProps;

    if ((!isLoading && isLoading !== prevIsLoading) || title !== prevTitle) {
        updateRobots(instance.props);
    }
    removeAdditionalCanonicals();

    replaceH1();
};

export default {
    'Scandipwa/Component/Router/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/Router/Container': {
        'member-function': {
            componentDidUpdate,
            containerProps
        }
    }
};
