/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import AreasReducer from 'Store/Areas/Areas.reducer';
import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import ProductReducer from 'Store/Product/Product.reducer';

import CategoryStickyFiltersReducer from './CategoryStickyFilters/CategoryStickyFilters.reducer';
import { getStaticReducers as sourceGetStaticReducers } from './index.source';
import LandingMenuReducer from './LandingMenu/LandingMenu.reducer';
import StockNotificationReducer from './StockNotification/StockNotification.reducer';

export * from 'SourceStore';

/** @namespace Scandipwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    ProductReducer,
    BreadcrumbsReducer,
    LandingMenuReducer,
    AreasReducer,
    StockNotificationReducer,
    CategoryStickyFiltersReducer
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => {
            // eslint-disable-next-line no-param-reassign
            store.asyncReducers[name] = reducer;
            store.injectReducer(name, reducer);
        }
    );

    return store;
}
