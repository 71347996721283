/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { lazy, PureComponent, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { MENU } from 'Component/Header/Header.config';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

import './MenuSidebar.style.scss';

export const Menu = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "menu" */
    'Component/Menu'
));

/** @namespace Scandipwa/Component/MenuSidebar/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMenuOpen: state.MenuReducer?.isMenuOpen,
    isMenuAnimated: state.MenuReducer?.isMenuAnimated
});

/** @namespace Scandipwa/Component/MenuSidebar/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state))
});

/** @namespace Scandipwa/Component/MenuSidebar/Container */
export class MenuSidebarContainer extends PureComponent {
    static propTypes = {
        updateMeta: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        isMenuOpen: PropTypes.bool,
        isMenuAnimated: PropTypes.bool
    };

    static defaultProps = {
        isMenuOpen: false,
        isMenuAnimated: false
    };

    componentDidMount() {
        const { updateMeta, changeHeaderState } = this.props;

        updateMeta({ title: __('Menu') });
        changeHeaderState({
            name: MENU,
            onBackClick: () => history.goBack()
        });
    }

    render() {
        const { isMenuOpen, isMenuAnimated } = this.props;

        return (
            <Suspense fallback={ null }>
                <main block="MenuSidebar" mods={ { isVisible: isMenuOpen, isMenuAnimated } }>
                    <Menu />
                </main>
            </Suspense>
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MenuSidebarContainer)
);
