/**
 * Clerk.io compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement, Suspense } from 'react';

import PopupStandard from 'Component/PopupStandard';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import Mobile from 'Util/Mobile';
import { lowPriorityLazy, waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

import { POWERSTEP_POPUP } from '../../component/PowerStep/PowerStep.config';
import { MINICART_WIDGET } from '../../component/Widgets/Widgets.config';

export const Popup = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "popup" */
    'Component/Popup'
));

export const PowerStep = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "power-step" */
    '../../component/PowerStep'
));
export const GreenCheckIcon = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "green-check" */
    'Component/GreenCheckIcon'
));

const renderHeading = () => (
    <h2 block="PowerStep" elem="Heading">
        <span block="PowerStep" elem="CheckMark">
            <Suspense fallback={ null }>
                <GreenCheckIcon />
            </Suspense>
        </span>
        <span block="PowerStep" elem="HeadingText">
            { __('Product added to cart') }
        </span>
    </h2>
);

export const renderPowerStepPopup = () => (
    <Suspense fallback={ null }>
        <Popup
          id={ POWERSTEP_POPUP }
          mix={ { block: 'PowerStepPopup' } }
        >
            <PopupStandard
              renderHeader={ renderHeading }
            >
                <Suspense fallback={ null }>
                    <PowerStep />
                </Suspense>
            </PopupStandard>
        </Popup>
    </Suspense>
);

export const addPowerstepPopupDispatch = async (args, callback, instance) => {
    const {
        product,
        product: { configurable_options = {}, type_id, id } = {},
        type
    } = instance.props;

    // vv state product is needed for quickview, wasn't able to create another function and plugin for it, sorry
    const {
        product: stateProduct = {},
        product: { configurable_options: stateConfigurableOptions = {} } = {}
    } = instance.state;

    const { parameters: stateParameters = {} } = instance.state;
    const { isPowerStepEnabled } = getStore().getState().ConfigReducer?.clerkConfig || {};

    if (!isPowerStepEnabled || type === MINICART_WIDGET || type_id === 'amgiftcard' || Mobile.any()) {
        callback(...args);

        return;
    }

    const {
        addProductToCart,
        cartId,
        showError
    } = instance.props;
    const products = instance.getMagentoProduct();
    const parameters = Object.keys(instance?.getParameters()).length ? instance.getParameters() : stateParameters;

    try {
        if (type_id === PRODUCT_TYPE.bundle) {
            await addProductToCart({ products, cartId, id });
        } else {
            await addProductToCart({ products, cartId });
        }

        const shouldPopupRender = Object.keys(product ? configurable_options : stateConfigurableOptions)
            .length === Object.keys(parameters).length;

        if (shouldPopupRender && !Mobile.any()) {
            product.parameters = parameters;
            stateProduct.parameters = parameters;

            waitForPriorityLoad().then(
                async () => {
                    const { showPowerStepPopup } = await import('../../util/popup');

                    showPowerStepPopup(product ?? stateProduct);
                }
            );
        }
    } catch (error) {
        if (error) {
            showError(error);
        }
    }
};

export const addPowerStepPopupToProductPage = (args, callback) => {
    const { isPowerStepEnabled } = getStore().getState().ConfigReducer?.clerkConfig || {};

    if (!isPowerStepEnabled) {
        return callback(...args);
    }

    const Component = callback(...args);

    return cloneElement(
        Component,
        Component.props,
        Children.map(
            Component.props.children,
            (child, i) => {
                const lastIndex = Component.props.children.length
                    ? Component.props.children.length - 1
                    : 0;

                if (i === lastIndex) {
                    return (
                        <>
                            { child }
                            { renderPowerStepPopup() }
                        </>
                    );
                }

                return child;
            }
        )
    );
};

export default {
    'Component/Product/Container': {
        'member-function': {
            addToCart: addPowerstepPopupDispatch
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            render: addPowerStepPopupToProductPage
        }
    },
    'Component/QuickView/Container': {
        'member-function': {
            addToCart: addPowerstepPopupDispatch
        }
    }
};
