import { noopFn } from 'Util/Common';

import { UPDATE_CATEGORY_STICKY_FILTERS_STATE } from './CategoryStickyFilters.action';

/** @namespace Scandipwa/Store/CategoryStickyFilters/Reducer/getInitialState */
export const getInitialState = () => ({
    stickyFiltersData: {
        selectedSort: {},
        isCurrentCategoryLoaded: false,
        isMatchingInfoFilter: false,
        onSortChange: noopFn,
        isContentFiltered: false,
        totalPages: 0,
        isAnchor: false,
        isSearchPage: false,
        onFilterButtonClick: noopFn,
        appliedFiltersCount: 0
    }
});

/** @namespace Scandipwa/Store/CategoryStickyFilters/Reducer/CategoryStickyFiltersReducer */
export const CategoryStickyFiltersReducer = (state = getInitialState(), action) => {
    const { type, stickyFiltersData } = action;

    switch (type) {
    case UPDATE_CATEGORY_STICKY_FILTERS_STATE:

        return {
            ...state,
            stickyFiltersData
        };

    default:
        return state;
    }
};

export default CategoryStickyFiltersReducer;
