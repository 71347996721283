/** @namespace Scandiweb/SeoSuite/Util/Scripts/changeTagName */
export function changeTagName(el, newTagName) {
    const n = document.createElement(newTagName);
    const attr = el.attributes;
    for (let i = 0, len = attr.length; i < len; ++i) {
        n.setAttribute(attr[i].name, attr[i].value);
    }
    n.innerHTML = el.innerHTML;
    el.parentNode.replaceChild(n, el);
}
